import React from 'react';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useCustomize } from '../../modules/context';
import Colors from '../organisms/Colors';
import FormField from './FormField';
import { NUMBER_FONT, POSITIONS } from '../../settings';
import {
  setMarkingPosition,
  setMarkingFont,
  setMarkingColor,
  getMarkingsByCode,
  deleteMarking,
  addMarking,
} from '../../modules/reducer';

interface ActiveProps {
  readonly isActive?: boolean;
}
const ImageGrid = styled.div<{ minWidth: string }>`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${(props) => props.minWidth}, 1fr)
  );
  grid-gap: 10px;
  > * {
    width: 100%;
  }
`;

const ImageIcon = styled.img<ActiveProps>`
  border: 2px solid black;
  border-color: ${(props) => (props.isActive ? '#08b8ff' : 'black')};
`;

export const NumberTab: React.FC = () => {
  const [customize, dispatch] = useCustomize();
  const numberMarkings = getMarkingsByCode(customize, 'number');
  const [activeTab, setActiveTab] = useState(0);

  const onChangePosition = useCallback(
    (marking, el) => {
      dispatch(setMarkingPosition(marking.index, el));
    },
    [dispatch]
  );

  const onChangeFont = useCallback(
    (marking, el) => {
      dispatch(setMarkingFont(marking.index, el));
    },
    [dispatch]
  );

  const onChangeColor = useCallback(
    (marking, color) => {
      dispatch(setMarkingColor(marking.index, color));
    },
    [dispatch]
  );

  const onClickTab = useCallback((index: number) => {
    setActiveTab(index);
  }, []);

  const onClickTabDelete = useCallback(
    (marking) => {
      dispatch(deleteMarking(marking.index));
      setActiveTab(numberMarkings.length - 2);
    },
    [dispatch, numberMarkings.length]
  );

  const onClickTabAdd = useCallback(
    (marking) => {
      dispatch(addMarking(marking));
      setActiveTab(numberMarkings.length);
    },
    [dispatch, numberMarkings.length]
  );

  return (
    <>
      {/* 番号入力欄 */}
      <div className="tabs is-large">
        <ul>
          {numberMarkings.map((marking, index: number) => {
            return (
              <li
                className={index === activeTab ? 'is-active' : ''}
                key={index}
              >
                <span>
                  <span onClick={() => onClickTab(index)}>
                    マーキング{index + 1}
                  </span>
                  <span
                    className="icon has-text-danger"
                    style={{ height: '1rem' }}
                    onClick={() => onClickTabDelete(marking)}
                  >
                    <i className="fas fa-times"></i>
                  </span>
                </span>
              </li>
            );
          })}
          <li>
            <button onClick={() => onClickTabAdd('number')}>
              マーキングを追加
            </button>
          </li>
        </ul>
      </div>

      {numberMarkings.map((marking, index) => {
        if (index !== activeTab) {
          return null;
        }
        return (
          <div key={index}>
            {/* 位置入力欄 */}
            <FormField
              label={'位置'}
              contents={
                <ImageGrid minWidth="60px">
                  {POSITIONS.number.map((el) => {
                    return (
                      <ImageIcon
                        key={el.id}
                        src={el.img}
                        alt=""
                        isActive={marking.position.id === el.id}
                        onClick={(ev) => onChangePosition(marking, el)}
                      />
                    );
                  })}
                </ImageGrid>
              }
            />
            {/* フォント入力欄 */}
            <FormField
              label={'フォント'}
              contents={
                <ImageGrid minWidth="40%">
                  {NUMBER_FONT.map((el) => {
                    return (
                      <ImageIcon
                        key={el.id}
                        src={el.imageUrl}
                        alt=""
                        isActive={marking.font.id === el.id}
                        onClick={(ev) => onChangeFont(marking, el)}
                      />
                    );
                  })}
                </ImageGrid>
              }
            />
            {/* 色入力欄 */}
            <FormField
              label={'色'}
              contents={
                <Colors
                  color={marking.color}
                  onClickColor={(color) => onChangeColor(marking, color)}
                />
              }
            />

            <p>
              ※フチカラーをご希望の場合は、注文フォームの備考欄にフチカラー希望の旨と、色をご記入ください。
            </p>
          </div>
        );
      })}
    </>
  );
};
