import React, { useReducer, useContext, createContext } from 'react';

import { initialState, reducer } from './reducer';

// Context related
const CustomizeContext = createContext();

export const CustomizeProvider = ({ children }: { children: React.FC }) => {
  const contextValue = useReducer(reducer, initialState);
  return (
    <CustomizeContext.Provider value={contextValue}>
      {children}
    </CustomizeContext.Provider>
  );
};

export const useCustomize = () => {
  const customize = useContext(CustomizeContext);
  return customize;
};
