import React from 'react';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import DesignTab from '../molecules/DesignTab';
import { NumberTab } from '../molecules/NumberTab';
import EstimationTab from '../molecules/EstimationTab';
import OrderTab from '../molecules/OrderTab';
import { useCustomize } from '../../modules/context';
import Colors from './Colors';
import FormField from '../molecules/FormField';
import {
  EMBLEM_ICONS,
  FONT_ICONS,
  SPONSOR_ICONS,
  POSITIONS,
} from '../../settings';
import {
  setMarkingPosition,
  setMarkingFont,
  setMarkingColor,
  setMarkingValue,
  setMarkingCode,
  getMarkingsByCode,
  deleteMarking,
  addMarking,
} from '../../modules/reducer';

import { uploadMedia } from '../../repository';

const Tab = styled.div`
  border-bottom: 1px solid black;
`;
const Header = styled.ul`
  border-bottom: 1px solid black;
  margin: 0;
  padding: 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
`;

const SubHeader = styled.ul`
  border-bottom: 1px solid #cdcdcd;
  margin: 0;
  padding: 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
`;

interface ActiveProps {
  readonly isActive?: boolean;
}
const HeaderTab = styled.li<ActiveProps>`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? '#eaf9ff' : 'auto')};
`;

const HeaderSubTab = styled.li<ActiveProps>`
  flex: 1;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? '#eaf9ff' : 'auto')};
`;

const Body = styled.div<{ activeTab: string }>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: ${(props) => (props.activeTab === 'order' ? '100%' : '300px')};
  flex: 1;
`;
const Inner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
`;

const ImageGrid = styled.div<{ minWidth: string }>`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${(props) => props.minWidth || '60px'}, 1fr)
  );
  grid-gap: 10px;
  > * {
    width: 100%;
  }
`;

const ImageIcon = styled.img<ActiveProps>`
  border: 2px solid black;
  border-color: ${(props) => (props.isActive ? '#08b8ff' : 'black')};
`;

const tabs = [
  {
    value: 'design',
    label: 'デザイン',
  },
  {
    value: 'print',
    label: 'プリント',
    subTabs: [
      {
        value: 'number',
        label: 'ナンバー',
      },
      {
        value: 'team',
        label: 'チーム',
      },
      {
        value: 'name',
        label: 'ネーム',
      },
      {
        value: 'emblem',
        label: 'エンブレム',
      },
      {
        value: 'sponsor',
        label: 'スポンサー',
      },
      // {
      //   value: 'originalLogo',
      //   label: 'オリジナルロゴ',
      // },
    ],
  },
  {
    value: 'estimation',
    label: '見積もり',
  },
  {
    value: 'order',
    label: '注文',
  },
];

const getSubTabs = (activeTab: string) => {
  const activeTabList = tabs.filter((el) => el.value === activeTab);
  return activeTabList[0].subTabs || [];
};

export default () => {
  const [activeTab, setActiveTab] = useState('design');

  const onClickTab = useCallback((e: any) => {
    const id = e.currentTarget.getAttribute('data-id');
    setActiveTab(id);
  }, []);

  return (
    <Tab>
      <Header>
        {tabs.map((el) => (
          <HeaderTab
            onClick={onClickTab}
            data-id={el.value}
            isActive={activeTab === el.value}
            key={el.value}
          >
            <span>{el.label}</span>
          </HeaderTab>
        ))}
      </Header>
      <Body activeTab={activeTab}>
        {activeTab === 'design' && <DesignTab />}
        {activeTab === 'print' && <SubTab tabs={getSubTabs(activeTab)} />}
        <EstimationTab
          style={{ display: activeTab === 'estimation' ? 'block' : 'none' }}
        />
        <OrderTab
          style={{ display: activeTab === 'order' ? 'block' : 'none' }}
        />
      </Body>
    </Tab>
  );
};

const SubTab = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].value);

  const onClickTab = useCallback((e: any) => {
    const id = e.currentTarget.getAttribute('data-id');
    setActiveTab(id);
  }, []);

  if (!tabs.length) {
    return null;
  }
  return (
    <>
      <SubHeader>
        {tabs.map((subTab) => (
          <HeaderSubTab
            onClick={onClickTab}
            data-id={subTab.value}
            isActive={activeTab === subTab.value}
            key={subTab.value}
          >
            <span>{subTab.label}</span>
          </HeaderSubTab>
        ))}
      </SubHeader>
      <Inner>{getSubTabContents(activeTab)}</Inner>
    </>
  );
};

const TeamTab: React.FC = () => {
  const [customize, dispatch] = useCustomize();
  const markings = getMarkingsByCode(customize, 'team');
  const [activeTab, setActiveTab] = useState(0);

  const onChangePosition = useCallback(
    (marking, el) => {
      dispatch(setMarkingPosition(marking.index, el));
    },
    [dispatch]
  );

  const onChangeFont = useCallback(
    (marking, el) => {
      dispatch(setMarkingFont(marking.index, el));
    },
    [dispatch]
  );

  const onChangeColor = useCallback(
    (marking, color) => {
      dispatch(setMarkingColor(marking.index, color));
    },
    [dispatch]
  );

  const onClickTab = useCallback((index: number) => {
    setActiveTab(index);
  }, []);

  const onClickTabDelete = useCallback(
    (marking) => {
      dispatch(deleteMarking(marking.index));
      setActiveTab(markings.length - 2);
    },
    [dispatch, markings.length]
  );

  const onClickTabAdd = useCallback(
    (marking) => {
      dispatch(addMarking(marking));
      setActiveTab(markings.length);
    },
    [dispatch, markings.length]
  );

  return (
    <>
      <div className="tabs is-large">
        <ul>
          {markings.map((marking, index: number) => {
            return (
              <li
                className={index === activeTab ? 'is-active' : ''}
                key={index}
              >
                <span>
                  <span onClick={() => onClickTab(index)}>
                    マーキング{index + 1}
                  </span>
                  <span
                    className="icon has-text-danger"
                    style={{ height: '1rem' }}
                    onClick={() => onClickTabDelete(marking)}
                  >
                    <i className="fas fa-times"></i>
                  </span>
                </span>
              </li>
            );
          })}
          <li>
            <button onClick={() => onClickTabAdd('team')}>
              マーキングを追加
            </button>
          </li>
        </ul>
      </div>
      {markings.map((marking, index) => {
        if (index !== activeTab) {
          return null;
        }
        return (
          <div key={marking}>
            {/* 位置入力欄 */}
            <FormField
              label={'位置'}
              contents={
                <ImageGrid minWidth={'60px'}>
                  {POSITIONS.team.map((el) => {
                    return (
                      <ImageIcon
                        key={el.id}
                        src={el.img}
                        alt=""
                        isActive={marking.position.id === el.id}
                        onClick={(ev) => onChangePosition(marking, el)}
                      />
                    );
                  })}
                </ImageGrid>
              }
            />
            {/* フォント入力欄 */}
            <FormField
              label={'フォント'}
              contents={
                <ImageGrid minWidth={'40%'}>
                  {FONT_ICONS.map((el) => {
                    return (
                      <ImageIcon
                        key={el.id}
                        src={el.imageUrl}
                        alt=""
                        isActive={marking.font.id === el.id}
                        onClick={(ev) => onChangeFont(marking, el)}
                      />
                    );
                  })}
                </ImageGrid>
              }
            />

            {/* 色入力欄 */}
            <FormField
              label={'色'}
              contents={
                <Colors
                  color={marking.color}
                  onClickColor={(color) => onChangeColor(marking, color)}
                />
              }
            />
            <p>
              ※フチカラーをご希望の場合は、注文フォームの備考欄にフチカラー希望の旨と、色をご記入ください。
            </p>
          </div>
        );
      })}
    </>
  );
};

const NameTab: React.FC = () => {
  const [customize, dispatch] = useCustomize();
  const markings = getMarkingsByCode(customize, 'name');
  const [activeTab, setActiveTab] = useState(0);

  const onChangePosition = useCallback(
    (marking, el) => {
      dispatch(setMarkingPosition(marking.index, el));
    },
    [dispatch]
  );

  const onChangeFont = useCallback(
    (marking, el) => {
      dispatch(setMarkingFont(marking.index, el));
    },
    [dispatch]
  );

  const onChangeColor = useCallback(
    (marking, color) => {
      dispatch(setMarkingColor(marking.index, color));
    },
    [dispatch]
  );

  const onClickTab = useCallback((index: number) => {
    setActiveTab(index);
  }, []);

  const onClickTabDelete = useCallback(
    (marking) => {
      dispatch(deleteMarking(marking.index));
      setActiveTab(markings.length - 2);
    },
    [dispatch, markings.length]
  );

  const onClickTabAdd = useCallback(
    (marking) => {
      dispatch(addMarking(marking));
      setActiveTab(markings.length);
    },
    [dispatch, markings.length]
  );

  return (
    <>
      <div className="tabs is-large">
        <ul>
          {markings.map((marking, index: number) => {
            return (
              <li
                className={index === activeTab ? 'is-active' : ''}
                key={index}
              >
                <span>
                  <span onClick={() => onClickTab(index)}>
                    マーキング{index + 1}
                  </span>
                  <span
                    className="icon has-text-danger"
                    style={{ height: '1rem' }}
                    onClick={() => onClickTabDelete(marking)}
                  >
                    <i className="fas fa-times"></i>
                  </span>
                </span>
              </li>
            );
          })}
          <li>
            <button onClick={() => onClickTabAdd('name')}>
              マーキングを追加
            </button>
          </li>
        </ul>
      </div>
      {markings.map((marking, index) => {
        if (index !== activeTab) {
          return null;
        }
        return (
          <div key={marking}>
            {/* 位置入力欄 */}
            <FormField
              label={'位置'}
              contents={
                <ImageGrid minWidth={'60px'}>
                  {POSITIONS.name.map((el) => {
                    return (
                      <ImageIcon
                        key={el.id}
                        src={el.img}
                        alt=""
                        isActive={marking.position.id === el.id}
                        onClick={(ev) => onChangePosition(marking, el)}
                      />
                    );
                  })}
                </ImageGrid>
              }
            />
            {/* フォント入力欄 */}
            <FormField
              label={'フォント'}
              contents={
                <ImageGrid minWidth={'40%'}>
                  {FONT_ICONS.map((el) => {
                    return (
                      <ImageIcon
                        key={el.id}
                        src={el.imageUrl}
                        alt=""
                        isActive={marking.font.id === el.id}
                        onClick={(ev) => onChangeFont(marking, el)}
                      />
                    );
                  })}
                </ImageGrid>
              }
            />
            {/* 色入力欄 */}
            <FormField
              label={'色'}
              contents={
                <Colors
                  color={marking.color}
                  onClickColor={(color) => onChangeColor(marking, color)}
                />
              }
            />

            <p>
              ※フチカラーをご希望の場合は、注文フォームの備考欄にフチカラー希望の旨と、色をご記入ください。
            </p>
          </div>
        );
      })}
    </>
  );
};

const EmblemTab: React.FC = () => {
  const [customize, dispatch] = useCustomize();
  const markings = getMarkingsByCode(customize, 'emblem');
  const [activeTab, setActiveTab] = useState(0);

  const onChangePosition = useCallback(
    (marking, el) => {
      dispatch(setMarkingPosition(marking.index, el));
    },
    [dispatch]
  );

  const onChangeEmblem = useCallback(
    (marking, el) => {
      dispatch(setMarkingValue(marking.index, el));
    },
    [dispatch]
  );

  const onClickTab = useCallback((index: number) => {
    setActiveTab(index);
  }, []);

  const onClickTabDelete = useCallback(
    (marking) => {
      dispatch(deleteMarking(marking.index));
      setActiveTab(markings.length - 2);
    },
    [dispatch, markings.length]
  );

  const onClickTabAdd = useCallback(
    (marking) => {
      dispatch(addMarking(marking));
      setActiveTab(markings.length);
    },
    [dispatch, markings.length]
  );

  return (
    <>
      <div className="tabs is-large">
        <ul>
          {markings.map((marking, index: number) => {
            return (
              <li
                className={index === activeTab ? 'is-active' : ''}
                key={index}
              >
                <span>
                  <span onClick={() => onClickTab(index)}>
                    マーキング{index + 1}
                  </span>
                  <span
                    className="icon has-text-danger"
                    style={{ height: '1rem' }}
                    onClick={() => onClickTabDelete(marking)}
                  >
                    <i className="fas fa-times"></i>
                  </span>
                </span>
              </li>
            );
          })}
          <li>
            <button onClick={() => onClickTabAdd('emblem')}>
              マーキングを追加
            </button>
          </li>
        </ul>
      </div>
      {markings.map((marking, index) => {
        if (index !== activeTab) {
          return null;
        }
        return (
          <div key={marking}>
            {/* 位置入力欄 */}
            <FormField
              label={'位置'}
              contents={
                <ImageGrid minWidth={'60px'}>
                  {POSITIONS.emblem.map((el) => {
                    return (
                      <ImageIcon
                        key={el.id}
                        src={el.img}
                        alt=""
                        isActive={marking.position.id === el.id}
                        onClick={(ev) => onChangePosition(marking, el)}
                      />
                    );
                  })}
                </ImageGrid>
              }
            />
            {/* エンブレム入力欄 */}
            <FormField
              label={'エンブレム'}
              contents={
                <ImageGrid minWidth={'60px'}>
                  {EMBLEM_ICONS.map((el) => {
                    return (
                      <ImageIcon
                        key={el}
                        src={el}
                        alt=""
                        isActive={marking.value === el}
                        onClick={(ev) => onChangeEmblem(marking, el)}
                      />
                    );
                  })}
                </ImageGrid>
              }
            />
            {marking.value.match(/original/) ? (
              <article className="message is-primary">
                <div className="message-body">
                  <p>
                    ※データ作成費4000円が発生します
                    <br />
                    ※ご注文お申し込みフォームにて画像を添付してください
                  </p>
                </div>
              </article>
            ) : null}
          </div>
        );
      })}
    </>
  );
};

const SponsorTab: React.FC = () => {
  const [customize, dispatch] = useCustomize();
  const markings = getMarkingsByCode(customize, 'sponsor');

  const onChangePosition = useCallback(
    (marking, el) => {
      dispatch(setMarkingPosition(marking.index, el));
    },
    [dispatch]
  );

  const onChangeEmblem = useCallback(
    (marking, el) => {
      dispatch(setMarkingCode(marking.index, el.id));
      dispatch(setMarkingValue(marking.index, el.imageUrl));
    },
    [dispatch]
  );

  const onChangeColor = useCallback(
    (marking, color) => {
      dispatch(setMarkingColor(marking.index, color));
    },
    [dispatch]
  );

  return (
    <>
      {markings.map((marking) => {
        return (
          <div key={marking}>
            {/* 位置入力欄 */}
            <FormField
              label={'位置'}
              contents={
                <ImageGrid minWidth={'60px'}>
                  {POSITIONS.sponsor.map((el) => {
                    return (
                      <ImageIcon
                        key={el.id}
                        src={el.img}
                        alt=""
                        isActive={marking.position.id === el.id}
                        onClick={(ev) => onChangePosition(marking, el)}
                      />
                    );
                  })}
                </ImageGrid>
              }
            />
            {/* スポンサー入力欄 */}
            <FormField
              label={'スポンサー'}
              contents={
                <ImageGrid minWidth={'60px'}>
                  {SPONSOR_ICONS.map((el: any, i) => {
                    return (
                      <ImageIcon
                        key={i}
                        src={el.imageUrl}
                        alt=""
                        isActive={marking.code === String(el.id)}
                        onClick={(ev) => onChangeEmblem(marking, el)}
                      />
                    );
                  })}
                </ImageGrid>
              }
            />
            {/* 色入力欄 */}
            {/*
              // https://trello.com/c/H5mYligi
              // 2色以上使用しているスポンサーに関しては、色変更を行わない
            */}
            {['13', '14', '3', '6', '8', 'original'].includes(marking.code) ? (
              <p>※選択中のスポンサーは色を変更することができません</p>
            ) : (
              <FormField
                label={'色'}
                contents={
                  <Colors
                    color={marking.color}
                    onClickColor={(color) => onChangeColor(marking, color)}
                  />
                }
              />
            )}
            {['original'].includes(marking.code) ? (
              <article className="message is-primary">
                <div className="message-body">
                  <p>
                    ※データ作成費4000円が発生します
                    <br />
                    ※ご注文お申し込みフォームにて画像を添付してください
                  </p>
                </div>
              </article>
            ) : null}
          </div>
        );
      })}
    </>
  );
};

const OriginalLogoTab: React.FC = () => {
  const [customize, dispatch] = useCustomize();
  const markings = getMarkingsByCode(customize, 'originalLogo');

  const onChangePosition = useCallback(
    (marking, el) => {
      dispatch(setMarkingPosition(marking.index, el));
    },
    [dispatch]
  );

  const onChangeFileInput = useCallback(
    async (marking, ev) => {
      const userUploadedImage = ev.target.files[0];
      const meta = {
        name: userUploadedImage.name,
        size: userUploadedImage.size,
        contentType: userUploadedImage.type,
      };

      const uploadTask = await uploadMedia(userUploadedImage, meta);
      const uploadedImage = await uploadTask.ref.getDownloadURL();
      dispatch(setMarkingValue(marking.index, uploadedImage));
    },
    [dispatch]
  );
  const onClickDelete = useCallback(
    (marking) => {
      dispatch(setMarkingValue(marking.index, ''));
    },
    [dispatch]
  );

  return (
    <>
      {markings.map((marking) => {
        return (
          <div key={marking}>
            {/* 位置入力欄 */}
            <FormField
              label={'位置'}
              contents={
                <ImageGrid>
                  {POSITIONS.emblem.map((el) => {
                    return (
                      <ImageIcon
                        key={el.id}
                        src={el.img}
                        alt=""
                        isActive={marking.position.id === el.id}
                        onClick={(ev) => onChangePosition(marking, el)}
                      />
                    );
                  })}
                </ImageGrid>
              }
            />
            {/* データ入稿欄 */}
            <FormField
              label={'ロゴデータ'}
              contents={
                <div className="file">
                  <label className="file-label">
                    <input
                      className="file-input"
                      type="file"
                      name="resume"
                      accept="image/*"
                      onChange={(e) => onChangeFileInput(marking, e)}
                    />
                    <span className="file-cta">
                      <span className="file-icon">
                        <i className="fas fa-upload"></i>
                      </span>
                      <span className="file-label">
                        ファイルを選択してください
                      </span>
                    </span>
                  </label>
                  <div>
                    {marking.value && <img src={marking.value} alt="" />}
                    {marking.value && (
                      <button
                        className="button is-medium"
                        onClick={() => onClickDelete(marking)}
                      >
                        削除する
                      </button>
                    )}
                  </div>
                </div>
              }
            />
          </div>
        );
      })}
    </>
  );
};

const getSubTabContents: (activeTab: string) => JSX.Element | null = (
  activeTab: string
) => {
  switch (activeTab) {
    case 'number':
      return <NumberTab />;
    case 'team':
      return <TeamTab />;
    case 'name':
      return <NameTab />;
    case 'emblem':
      return <EmblemTab />;
    case 'sponsor':
      return <SponsorTab />;
    case 'originalLogo':
      return <OriginalLogoTab />;
    default:
      return null;
  }
};
