import firebase from './firebase';

const db = firebase.firestore();
const storageRef = firebase.storage().ref();

// create
export const createOrder = (payload) => {
  return db.collection('orders').add(payload);
};

// save design
export const saveDesign = (payload) => {
  return db.collection('designs').add(payload);
};

// get design
export const getDesign = (code: string) => {
  return db.doc(`designs/${code}`).get();
};

// get order
export const getOrder = (code: string) => {
  return db.doc(`orders/${code}`).get();
};

// upload media file
export const uploadMedia = (file, meta) => {
  const newRef = storageRef.child(meta.name);
  return newRef.put(file, meta);
};
