import React from 'react';

export default ({ label, contents }) => {
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control">{contents}</div>
    </div>
  );
};
