import produce from 'immer';
import { Action, CustomizeType } from 'types';
import {
  colorCodes,
  designs,
  EMBLEM_ICONS,
  FONT_ICONS,
  NUMBER_FONT,
  SPONSOR_ICONS,
  POSITIONS,
} from '../settings';

export const SET_DESIGN = 'SET_DESIGN';
export const SET_NECK = 'SET_NECK';
export const SET_PARTS_LIST = 'SET_PARTS_LIST';
export const SET_PARTS_COLOR = 'SET_PARTS_COLOR';
export const SET_PLAYER_LIST = 'SET_PLAYER_LIST';
export const SET_ORDER_FORM = 'SET_ORDER_FORM';
export const SET_MARKING = 'SET_MARKING';
export const UPDATE_CUSTOMIZE = 'UPDATE_CUSTOMIZE';
export const SET_MARKING_POSITION = 'SET_MARKING_POSITION';
export const SET_MARKING_FONT = 'SET_MARKING_FONT';
export const SET_MARKING_COLOR = 'SET_MARKING_COLOR';
export const SET_MARKING_VALUE = 'SET_MARKING_VALUE';
export const SET_MARKING_CODE = 'SET_MARKING_CODE';
export const SET_NECK_COLOR = 'SET_NECK_COLOR';
export const DELETE_MARKING = 'DELETE_MARKING';
export const ADD_MARKING = 'ADD_MARKING';

export function setMarking(index, value) {
  return {
    type: 'SET_MARKING',
    value: {
      index,
      value,
    },
  };
}

export function setMarkingPosition(index, value) {
  return {
    type: SET_MARKING_POSITION,
    value: {
      index,
      value,
    },
  };
}

export function setMarkingFont(index, value) {
  return {
    type: SET_MARKING_FONT,
    value: {
      index,
      value,
    },
  };
}

export function setMarkingColor(index, value) {
  return {
    type: SET_MARKING_COLOR,
    value: {
      index,
      value,
    },
  };
}

export function setMarkingValue(index, value) {
  return {
    type: SET_MARKING_VALUE,
    value: {
      index,
      value,
    },
  };
}

export function setMarkingCode(index, value) {
  return {
    type: SET_MARKING_CODE,
    value: {
      index,
      value,
    },
  };
}

export function updateCustomize(payload: CustomizeType) {
  return {
    type: 'UPDATE_CUSTOMIZE',
    value: payload,
  };
}

export function getMarkingsByCode(customize, code: string) {
  return customize.shirt.marking
    .map((el, index: number) => ({ ...el, index }))
    .filter((el) => el.id === code);
}

export function updateNeckColor(payload: any) {
  return {
    type: SET_NECK_COLOR,
    value: payload,
  };
}

export function deleteMarking(index: number) {
  return {
    type: DELETE_MARKING,
    value: index,
  };
}

export function addMarking(marking: string) {
  const template = getMarkingTemplateById(marking);
  return {
    type: ADD_MARKING,
    value: template,
  };
}

function getMarkingTemplateById(id: string) {
  switch (id) {
    case 'number':
      return {
        id: 'number',
        position: { ...POSITIONS.number[0] },
        font: { ...NUMBER_FONT[0] },
        color: { ...colorCodes[0] },
        value: '10', // 固定
      };
    case 'team':
      return {
        id: 'team',
        position: { ...POSITIONS.team[0] },
        font: { ...FONT_ICONS[0] },
        color: { ...colorCodes[0] },
        value: 'TEAM', // 固定
      };
    case 'name':
      return {
        id: 'name',
        position: { ...POSITIONS.name[0] },
        font: { ...FONT_ICONS[0] },
        color: { ...colorCodes[0] },
        value: 'NAME', // 固定
      };
    case 'emblem':
      return {
        id: 'emblem',
        position: { ...POSITIONS.emblem[0] },
        value: EMBLEM_ICONS[0],
      };
    case 'sponsor':
      return {
        id: 'sponsor',
        position: { ...POSITIONS.sponsor[0] },
        color: { ...colorCodes[0] },
        code: SPONSOR_ICONS[0].id,
        value: SPONSOR_ICONS[0].imageUrl,
      };
    case 'originalLogo':
      return {
        id: 'originalLogo',
        position: { ...POSITIONS.emblem[0] }, // エンブレムと同じ位置
        value: '',
      };
  }
}

// Reducer related
export const initialState: CustomizeType = {
  shirt: {
    design: {
      id: designs[0].id,
      name: null,
      parts: [],
    },
    marking: [
      getMarkingTemplateById('number'),
      getMarkingTemplateById('team'),
      getMarkingTemplateById('name'),
      getMarkingTemplateById('emblem'),
      getMarkingTemplateById('sponsor'),
      getMarkingTemplateById('originalLogo'),
    ],
  },
  neck: {
    type: 2,
    name: '丸首',
    color: {
      colorCode: null,
    },
  },
  orderForm: {
    name: '',
    tel: '',
    organization: '',
    email: '',
    useDay: '',
    deliveryAddress: '',
    deliveryName: '',
    desireDeliveryTime: '',
    receiptNeeded: '',
    paymentType: '',
    remark: '',
    playerList: [],
  },
};

export const reducer = produce((draft: any, action: Action) => {
  switch (action.type) {
    // デザインの更新
    case SET_DESIGN:
      draft.shirt.design.id = action.value;
      draft.shirt.design.parts = [];
      return;
    // 襟デザインの更新
    case SET_NECK:
      draft.neck.type = action.value;
      return;
    // デザインパーツの更新
    case SET_PARTS_LIST:
      draft.shirt.design.parts = action.value.map((el) => {
        return {
          id: null,
          name: el,
          colorCode: null,
          // colorCode: "#FFD700"
        };
      });
      return;
    // デザインパーツの色更新
    case SET_PARTS_COLOR:
      const { partsName, colorCode } = action.value;
      const target = draft.shirt.design.parts.filter(
        (el) => el.name === partsName
      )[0];
      target.colorCode = colorCode;
      return;

    // マーキングの更新
    case SET_MARKING:
      const { index, value } = action.value;
      draft.shirt.marking[index] = { ...draft.shirt.marking[index], ...value };
      return;

    // マーキング位置の更新
    case SET_MARKING_POSITION:
      (() => {
        const { index, value } = action.value;
        draft.shirt.marking[index].position = { ...value };
      })();
      return;

    // マーキングフォントの更新
    case SET_MARKING_FONT:
      (() => {
        const { index, value } = action.value;
        draft.shirt.marking[index].font = { ...value };
      })();
      return;

    // マーキングカラーの更新
    case SET_MARKING_COLOR:
      (() => {
        const { index, value } = action.value;
        draft.shirt.marking[index].color = { ...value };
      })();
      return;

    // 襟色の更新
    case SET_NECK_COLOR:
      (() => {
        draft.neck.color = { ...action.value };
      })();
      return;

    // マーキングの値の更新
    case SET_MARKING_VALUE:
      (() => {
        const { index, value } = action.value;
        draft.shirt.marking[index].value = value;
      })();
      return;

    // マーキングのcodeの更新
    case SET_MARKING_CODE:
      (() => {
        const { index, value } = action.value;
        draft.shirt.marking[index].code = value;
      })();
      return;

    // プレイヤーリストの更新
    case SET_PLAYER_LIST:
      draft.orderForm = {
        ...draft.orderForm,
        playerList: action.value,
      };
      return;

    // 注文フォームの更新
    case SET_ORDER_FORM:
      draft.orderForm = { ...draft.orderForm, ...action.value };
      return;

    // 上書き
    case UPDATE_CUSTOMIZE:
      return (draft = action.value);

    // マーキング追加
    case ADD_MARKING:
      draft.shirt.marking.push({
        ...action.value,
        index: draft.shirt.marking.length,
      });
      return;

    // マーキング削除
    case DELETE_MARKING:
      draft.shirt.marking = draft.shirt.marking.filter(
        (_, index: number) => index !== action.value
      );
      return;
  }
});
