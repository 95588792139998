import React from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';
import { designs, colorCodes, necks } from '../../settings';
import { useCustomize } from '../../modules/context';
import {
  SET_DESIGN,
  SET_NECK,
  SET_PARTS_COLOR,
  updateNeckColor,
} from '../../modules/reducer';

const BodyTab = styled.li`
  height: 300px;
  flex: 1;
  margin: 10px;
`;

const DesignsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  grid-gap: 10px;
  > * {
    width: 100%;
  }
`;

interface DesignProps {
  isActive?: Boolean;
}

const Design = styled.img<DesignProps>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  grid-gap: 10px;
  > * {
    width: 100%;
  }
  border: 1px solid ${(props) => (props.isActive ? 'blue' : '#cdcdcd')};
`;

const ColorsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
  grid-gap: 10px;
  > * {
    width: 100%;
  }
`;

interface ColorProps {
  isActive?: Boolean;
  color: String;
}

const Color = styled.a<DesignProps>`
  display: grid;
  grid-gap: 10px;
  > * {
    width: 100%;
  }
  border: 1px solid ${(props) => (props.isActive ? 'blue' : '#cdcdcd')};
  background-color: ${(props) => props.color};
  height: 40px;
`;

const ColorInner = styled.img<DesignProps>`
  background-color: ${(props) => props.color};
  width: 100%;
  height: 100%;
`;

const PartsHeader = styled.h3`
  font-size: 16px;
  margin-bottom: 10px;
`;

const PartsContainer = styled.div`
  > * + * {
    margin-top: 20px;
  }
`;

export default ({ onClickColor }) => {
  return (
    <BodyTab>
      <Designs />
      <Parts />
      <Necks />
    </BodyTab>
  );
};

const Necks = () => {
  const _ = useCustomize();
  // const { type } = customize.neck;

  const onCLickNeck = useCallback(
    (index) => {
      _[1]({
        type: SET_NECK,
        value: index,
      });
    },
    [_]
  );

  return (
    <>
      <h2 style={{ marginTop: 20 }}>襟の色</h2>
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        {necks.map((el) => {
          return (
            <button
              onClick={() => onCLickNeck(el.id)}
              className="button"
              key={el.name}
            >
              {el.name}
            </button>
          );
        })}
      </div>
      <NeckColors />
    </>
  );
};

const Designs = () => {
  const [customize, dispatch] = useCustomize();
  const { id } = customize.shirt.design;

  const onCLickDesign = useCallback(
    (el) => {
      dispatch({
        type: SET_DESIGN,
        value: el,
      });
    },
    [dispatch]
  );

  return (
    <>
      <h2>デザイン</h2>
      <DesignsGrid>
        {designs.map((el, index) => {
          return (
            <Design
              src={el.imageUrl}
              alt=""
              isActive={id === el.id}
              key={index}
              onClick={() => onCLickDesign(el.id)}
            />
          );
        })}
      </DesignsGrid>
    </>
  );
};

const Parts = () => {
  const [customize] = useCustomize();
  const { parts } = customize.shirt.design;
  return (
    <PartsContainer>
      <h2>パーツの色</h2>
      {parts.map((el, index) => {
        return (
          <div key={index}>
            <PartsHeader>
              {el.name} {!el.colorCode && `-未選択-`}
            </PartsHeader>
            <Colors partsName={el.name} />
          </div>
        );
      })}
    </PartsContainer>
  );
};

const Colors = ({ partsName }) => {
  const [customize, dispatch] = useCustomize();
  const colorCode = customize.shirt.design.parts.filter(
    (el) => el.name === partsName
  )[0].colorCode;

  const onClickColor = useCallback(
    (partsName, colorCode) => {
      dispatch({
        type: SET_PARTS_COLOR,
        value: {
          partsName,
          colorCode,
        },
      });
    },
    [dispatch]
  );

  return (
    <ColorsGrid>
      {colorCodes.map((el, index) => {
        return (
          <Color
            href="#"
            isActive={colorCode === el.colorCode}
            onClick={() => onClickColor(partsName, el.colorCode)}
            key={el.name}
          >
            <ColorInner color={el.colorCode} />
          </Color>
        );
      })}
    </ColorsGrid>
  );
};

const NeckColors = () => {
  const [customize, dispatch] = useCustomize();
  const colorCode = customize.neck.color.colorCode;

  const onClickColor = useCallback(
    (color) => {
      dispatch(updateNeckColor({ ...color }));
    },
    [dispatch]
  );

  return (
    <ColorsGrid>
      {colorCodes.map((el, index) => {
        return (
          <Color
            href="#"
            isActive={colorCode === el.colorCode}
            onClick={() => onClickColor(el)}
            key={el.name}
          >
            <ColorInner color={el.colorCode} />
          </Color>
        );
      })}
    </ColorsGrid>
  );
};
