import React, { useState, useCallback, useEffect } from 'react';
// import { useCallback, useState } from "react";
import styled from 'styled-components';
import { sizes } from '../../settings';
import { createOrder, uploadMedia } from '../../repository';
import { useCustomize } from '../../modules/context';
import { SET_ORDER_FORM, SET_PLAYER_LIST } from '../../modules/reducer';
import FormField from '../molecules/FormField';
import { prefectures } from '../../util';
import Viewer from '../organisms/Viewer';

const BodyTab = styled.li`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 10px;
  height: 100vh;
`;
const Note = styled.p`
  font-size: 16px;
  color: #ed4758;
`;

export default ({ style }) => {
  return (
    <BodyTab style={style}>
      <PlayerListForm />
      <OrderForm />
    </BodyTab>
  );
};

const OrderForm = () => {
  const [isConfirmModalShown, setIsConfirmModalShown] = useState(false);
  const [customize, dispatch] = useCustomize();
  const [form, setForm] = useState({
    name: '',
    organization: '',
    gakuwari: '',
    tel: '',
    email: '',
    useDay: '',
    teamName: '',
    postalCode: '',
    prefecture: '',
    address1: '',
    address2: '',
    address3: '',
    deliveryName: '',
    desireDeliveryTime: '',
    shippingImmediately: 'する',
    receiptNeeded: '不要',
    receiptName: '',
    receiptNote: '',
    paymentType: '',
    originalLogo: '',
    originalSponsor: '',
    remark: '',
  });

  const updateForm = useCallback(
    (key, value) => {
      const data = { ...form, [key]: value };
      setForm(data);
      console.log(data);
      console.log(form);
      console.log(key);
      console.log(value);
      console.log(form);
      dispatch({
        type: SET_ORDER_FORM,
        value: data,
      });
    },
    [dispatch, form]
  );

  const updateFormMulti = useCallback(
    (value) => {
      const data = { ...form, ...value };
      setForm(data);
      dispatch({
        type: SET_ORDER_FORM,
        value: data,
      });
    },
    [dispatch, form]
  );

  const handleChange = useCallback(
    (event) => {
      console.log(event.target);
      updateForm(event.target.name, event.target.value);
    },
    [updateForm]
  );

  const onChangePostalCode = useCallback(
    async (event) => {
      try {
        event.persist();
        const value = event.target.value;
        if (value.length < 7) {
          updateForm(event.target.name, value);
          return;
        }
        const url = `https://madefor.github.io/postal-code-api/api/v1/${value.slice(
          0,
          3
        )}/${value.slice(3, 7)}.json`;
        const res = await fetch(url)
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            return res.data[0].ja;
          })
          .catch((err) => {
            // 該当するデータ無し
            return {};
          });
        updateFormMulti({
          postalCode: value,
          prefecture: res.prefecture,
          address1: res.address1,
          address2: res.address2,
          address3: res.address3,
        });
      } finally {
      }
    },
    [updateFormMulti, updateForm]
  );

  const onChangeFileInput = useCallback(
    async (name, ev) => {
      const userUploadedImage = ev.target.files[0];
      const meta = {
        name: userUploadedImage.name,
        size: userUploadedImage.size,
        contentType: userUploadedImage.type,
      };

      const uploadTask = await uploadMedia(userUploadedImage, meta);
      const uploadedImage = await uploadTask.ref.getDownloadURL();

      const data = { ...form, [name]: uploadedImage };
      setForm(data);
      dispatch({
        type: SET_ORDER_FORM,
        value: data,
      });
    },
    [dispatch, form]
  );

  const onClickDelete = useCallback(
    (name) => {
      const data = { ...form, [name]: '' };
      setForm(data);
      dispatch({
        type: SET_ORDER_FORM,
        value: data,
      });
    },
    [dispatch, form]
  );

  const handleSubmit = useCallback((event) => {
    // 確認画面へ が押下されたので、確認モーダルを表示
    event.preventDefault();
    setIsConfirmModalShown(true);
  }, []);

  const onClickSubmit = useCallback(async () => {
    await createOrder(customize);
    alert('ご注文を承りました');
    setIsConfirmModalShown(false);
  }, [customize]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label className="label">名前</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="田中太郎"
              name="name"
              value={form.name}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">学校名・クラス名</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="〇〇高校〇年〇組"
              name="organization"
              value={form.organization}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">電話番号</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="09098765432"
              name="tel"
              value={form.tel}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">メールアドレス</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="email@example.com"
              name="email"
              value={form.email}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* データ入稿欄 */}
        <FormField
          label={'オリジナルロゴデータ'}
          contents={
            <div className="file">
              {!form.originalLogo && (
                <label className="file-label">
                  <input
                    className="file-input"
                    type="file"
                    name="resume"
                    accept="image/*"
                    onChange={(e) => onChangeFileInput('originalLogo', e)}
                  />
                  <span className="file-cta">
                    <span className="file-icon">
                      <i className="fas fa-upload"></i>
                    </span>
                    <span className="file-label">
                      ファイルを選択してください
                    </span>
                  </span>
                </label>
              )}
              <div>
                {form.originalLogo && (
                  <div>
                    <img src={form.originalLogo} alt="" width="200" />
                  </div>
                )}
                {form.originalLogo && (
                  <div>
                    <button
                      className="button is-medium"
                      onClick={() => onClickDelete('originalLogo')}
                    >
                      削除する
                    </button>
                  </div>
                )}
              </div>
            </div>
          }
        />

        <FormField
          label={'オリジナルスポンサーデータ'}
          contents={
            <div className="file">
              {!form.originalSponsor && (
                <label className="file-label">
                  <input
                    className="file-input"
                    type="file"
                    name="resume"
                    accept="image/*"
                    onChange={(e) => onChangeFileInput('originalSponsor', e)}
                  />
                  <span className="file-cta">
                    <span className="file-icon">
                      <i className="fas fa-upload"></i>
                    </span>
                    <span className="file-label">
                      ファイルを選択してください
                    </span>
                  </span>
                </label>
              )}
              <div>
                {form.originalSponsor && (
                  <div>
                    <img src={form.originalSponsor} alt="" width="200" />
                  </div>
                )}
                {form.originalSponsor && (
                  <div>
                    <button
                      className="button is-medium"
                      onClick={() => onClickDelete('originalSponsor')}
                    >
                      削除する
                    </button>
                  </div>
                )}
              </div>
            </div>
          }
        />

        <div className="field">
          <label className="label">チーム名</label>
          <p>※チーム名を希望の方のみご記入ください。</p>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder=""
              name="teamName"
              value={form.teamName}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">ご使用日</label>
          <div className="control">
            <input
              className="input"
              type="date"
              placeholder=""
              name="useDay"
              value={form.useDay}
              onChange={handleChange}
            />
          </div>
        </div>

        <h2>配送先</h2>
        <div className="field">
          <label className="label">郵便番号(ハイフン無し)</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="1112222"
              name="postalCode"
              value={form.postalCode}
              onChange={onChangePostalCode}
              maxLength={7}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">都道府県</label>
          <div className="control">
            <div className="select">
              <select
                name="prefecture"
                value={form.prefecture}
                onChange={handleChange}
              >
                {prefectures.map((o) => (
                  <option value={o.name} key={o.code}>
                    {o.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="field">
          <label className="label">市区町村</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder=""
              name="address1"
              value={form.address1}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">住所</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder=""
              name="address2"
              value={form.address2}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">建物名</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder=""
              name="address3"
              value={form.address3}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">配送先宛名</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder=""
              name="deliveryName"
              value={form.deliveryName}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* <div className="field">
          <label className="label">希望学割内容</label>
          <div className="control">
            <textarea
              className="textarea"
              placeholder="Mサイズはありますか？"
              name="gakuwari"
              value={form.gakuwari}
              onChange={handleChange}
            ></textarea>
          </div>
        </div> */}

        <div className="field">
          <label className="label">完成次第、商品の発送を希望</label>
          <div className="control">
            <label className="radio">
              <input
                type="radio"
                name="shippingImmediately"
                value={'する'}
                checked={form.shippingImmediately === 'する'}
                onChange={handleChange}
              />
              する
            </label>
            <label className="radio">
              <input
                type="radio"
                name="shippingImmediately"
                value={'しない'}
                checked={form.shippingImmediately === 'しない'}
                onChange={handleChange}
              />
              しない
            </label>
          </div>
        </div>

        <div className="field">
          <label className="label">お届希望時間</label>
          <div className="control">
            <div className="select">
              <select
                name="desireDeliveryTime"
                value={form.desireDeliveryTime}
                onChange={handleChange}
              >
                <option value="午前中">午前中</option>
                <option value="14:00-16:00">14:00-16:00</option>
                <option value="16:00-18:00">16:00-18:00</option>
                <option value="18:00-20:00">18:00-20:00</option>
                <option value="18:00-20:00">19:00-21:00</option>
              </select>
            </div>
          </div>
        </div>

        <div className="field">
          <label className="label">領収書の有無</label>
          <div className="control">
            <label className="radio">
              <input
                type="radio"
                name="receiptNeeded"
                value={'必要'}
                checked={form.receiptNeeded === '必要'}
                onChange={handleChange}
              />
              必要
            </label>
            <label className="radio">
              <input
                type="radio"
                name="receiptNeeded"
                value={'不要'}
                checked={form.receiptNeeded === '不要'}
                onChange={handleChange}
              />
              不要
            </label>
          </div>
        </div>

        {form.receiptNeeded === '必要' && (
          <>
            <div className="field">
              <label className="label">宛名</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder=""
                  name="receiptName"
                  value={form.receiptName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">但し書き</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder=""
                  name="receiptNote"
                  value={form.receiptNote}
                  onChange={handleChange}
                />
              </div>
            </div>
          </>
        )}
        <div className="field">
          <label className="label">お支払い方法</label>
          <div className="control">
            <div className="select">
              <select
                name="paymentType"
                value={form.paymentType}
                onChange={handleChange}
              >
                <option value="代金引換">代金引換</option>
                <option value="銀行振込">銀行振込</option>
                <option value="後払い">後払い</option>
              </select>
            </div>
          </div>
        </div>

        {form.paymentType === '後払い' && (
          <p>※ 商品到着後、3日以内の支払いをお願い致します</p>
        )}

        <div className="field">
          <label className="label">備考</label>
          <div className="control">
            <textarea
              className="textarea"
              placeholder="Mサイズはありますか？"
              name="remark"
              value={form.remark}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>

        <div className="field">
          <div className="control">
            <button className="button is-medium is-fullwidth">
              確認画面へ
            </button>
          </div>
        </div>
      </form>

      <div className={`modal ${isConfirmModalShown ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">ご注文情報のご確認</p>
            </header>

            {customize.orderForm && (
              <section className="modal-card-body">
                <h2>ご注文デザイン</h2>

                {isConfirmModalShown && <Viewer preview />}

                <h2 style={{ marginTop: '20px' }}>注文内容</h2>
                {customize.orderForm.playerList && (
                  <div>
                    <p>注文枚数: {customize.orderForm.playerList.length}枚 </p>
                    <table className="table" style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <th>番号</th>
                          <th>袖</th>
                          <th>サイズ</th>
                          <th>ネーム</th>
                        </tr>
                        {customize.orderForm.playerList.map((o, i) => (
                          <tr key={i}>
                            <td>{o.number}</td>
                            <td>{o.sleeve}</td>
                            <td>{o.size}</td>
                            <td>{o.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                <h2 style={{ marginTop: '20px' }}>ご注文者情報</h2>
                {(() => {
                  const {
                    name,
                    organization,
                    tel,
                    email,
                    useDay,
                    teamName,
                    originalLogo,
                    originalSponsor,
                    postalCode,
                    prefecture,
                    address1,
                    address2,
                    address3,
                    deliveryName,
                    desireDeliveryTime,
                    shippingImmediately,
                    receiptNeeded,
                    receiptName,
                    receiptNote,
                    paymentType,
                    remark,
                  } = customize.orderForm;
                  console.log(customize.orderForm.name);
                  console.log(name);
                  return (
                    <table className="table" style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <th>項目</th>
                          <th>内容</th>
                        </tr>
                        <tr>
                          <td>お名前</td>
                          <td>{name}</td>
                        </tr>
                        <tr>
                          <td>学校名・クラス名</td>
                          <td>{organization}</td>
                        </tr>
                        <tr>
                          <td>お電話番号</td>
                          <td>{tel}</td>
                        </tr>
                        <tr>
                          <td>メールアドレス</td>
                          <td>{email}</td>
                        </tr>
                        {!!originalLogo && (
                          <tr>
                            <td>オリジナルロゴ</td>
                            <td>
                              <img src={originalLogo} alt="" />
                            </td>
                          </tr>
                        )}
                        {!!originalSponsor && (
                          <tr>
                            <td>オリジナルスポンサー</td>
                            <td>
                              <img src={originalSponsor} alt="" />
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>チーム名</td>
                          <td>{teamName}</td>
                        </tr>
                        <tr>
                          <td>使用日</td>
                          <td>{useDay}</td>
                        </tr>
                        <tr>
                          <td colSpan={2}>配送先情報</td>
                        </tr>
                        <tr>
                          <td>郵便番号</td>
                          <td>{postalCode}</td>
                        </tr>
                        <tr>
                          <td>都道府県</td>
                          <td>{prefecture}</td>
                        </tr>
                        <tr>
                          <td>市区町村</td>
                          <td>{address1}</td>
                        </tr>
                        <tr>
                          <td>住所</td>
                          <td>{address2}</td>
                        </tr>
                        <tr>
                          <td>建物名</td>
                          <td>{address3}</td>
                        </tr>
                        <tr>
                          <td>配送先宛名</td>
                          <td>{deliveryName}</td>
                        </tr>
                        <tr>
                          <td>完成次第、商品の発送を希望</td>
                          <td>{shippingImmediately}</td>
                        </tr>
                        <tr>
                          <td>お届希望時間</td>
                          <td>{desireDeliveryTime}</td>
                        </tr>
                        <tr>
                          <td>領収書</td>
                          <td>{receiptNeeded}</td>
                        </tr>
                        {receiptNeeded === '必要' && (
                          <tr>
                            <td>宛名/但し書き</td>
                            <td>
                              {receiptName}/{receiptNote}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>支払い方法</td>
                          <td>{paymentType}</td>
                        </tr>
                        <tr>
                          <td>備考</td>
                          <td>{remark}</td>
                        </tr>
                      </tbody>
                    </table>
                  );
                })()}
                <h2>ご注意事項</h2>
                <Note>
                  ※ご注文送信後の変更、キャンセルは出来かねます。よくご確認いただいたうえで、お申し込みください。
                  <br />
                  ※ご注文送信後、担当スタッフより請求書と納期案内のメールをお送りします。
                  <br />
                  2
                  日経ってもメールが届かない場合は、お手数ですが下記番号までお電話ください。
                  <br />
                  03-6420-3801
                  <br />
                </Note>
              </section>
            )}
            <footer className="modal-card-foot">
              <button
                style={{ fontWeight: 'bold' }}
                className="button is-default is-small"
                onClick={() => {
                  setIsConfirmModalShown(false);
                }}
              >
                修正する
              </button>
              <button
                style={{ fontWeight: 'bold' }}
                className="button is-success is-small"
                onClick={() => {
                  onClickSubmit();
                }}
              >
                送信する
              </button>
            </footer>
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => setIsConfirmModalShown(false)}
        ></button>
      </div>
    </>
  );
};

type player = {
  number: string;
  sleeve: string;
  size: string;
  name: string;
};

const initialPlayer: player = {
  number: '',
  sleeve: '半袖',
  size: '150',
  name: '',
};

const PlayerListForm = () => {
  const [customize, dispatch] = useCustomize();
  const [number, setNumber] = useState('');
  const [numberMode, setNumberMode] = useState(false);
  const [form, setForm] = useState(
    [...new Array(5)].map((el): player => ({ ...initialPlayer }))
  );

  useEffect(() => {
    // 初期化
    if (!customize.orderForm.playerList.length) {
      dispatch({
        type: SET_PLAYER_LIST,
        value: form,
      });
    }
  }, [dispatch, form, customize.orderForm.playerList.length]);

  const onChangeQuantity = useCallback((e: any) => {
    const quantity = +e.currentTarget.value;
    setForm(
      [...new Array(quantity)].map((el): player => ({ ...initialPlayer }))
    );
  }, []);

  const handleChange = useCallback(
    (event: any, index: number) => {
      const updatedForm = [...form];
      updatedForm[index] = {
        ...updatedForm[index],
        [event.target.name]: event.target.value,
      };
      setForm(updatedForm);
      dispatch({
        type: SET_PLAYER_LIST,
        value: updatedForm,
      });
    },
    [dispatch, form]
  );

  const onChangeNumber = useCallback((event) => {
    const { value } = event.target;
    setNumber(value);
  }, []);

  const mapNumber = useCallback(() => {
    const updatedForm = form.map((el) => {
      return {
        ...el,
        number,
      };
    });
    setNumber('');
    setNumberMode(false);
    setForm(updatedForm);
    dispatch({
      type: SET_PLAYER_LIST,
      value: updatedForm,
    });
  }, [number, dispatch, form]);

  const onChangeNumberMode = useCallback(
    (event) => {
      setNumberMode(!numberMode);
    },
    [numberMode]
  );

  return (
    <>
      <h1>注文明細</h1>
      <div className="select">
        <select name="" id="" onChange={onChangeQuantity}>
          {[...new Array(50)].map((el, index) => {
            return (
              <option value={index + 5} key={index}>
                {index + 5}
              </option>
            );
          })}
        </select>
      </div>

      <div className="field">
        <div className="control">
          <input
            id="numberMode"
            type="checkbox"
            checked={numberMode}
            onChange={onChangeNumberMode}
          />
          <label htmlFor="numberMode">番号一括反映</label>
        </div>
      </div>

      {numberMode && (
        <>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="77"
                name="number"
                value={number}
                onChange={onChangeNumber}
              />
            </div>
          </div>

          <div className="field">
            <div className="control">
              <button className="button is-small" onClick={mapNumber}>
                一括反映する
              </button>
            </div>
          </div>
        </>
      )}

      <h1>注文者情報</h1>
      <table className="table">
        <tbody>
          <tr>
            <th>番号</th>
            <th>袖</th>
            <th>サイズ</th>
            <th>ネーム</th>
          </tr>
          {form.map((__, index) => {
            return (
              <tr key={index}>
                <td className="number-column">
                  <input
                    className="input"
                    type="text"
                    name="number"
                    value={form[index].number}
                    onChange={(event) => handleChange(event, index)}
                    maxLength={4}
                  />
                </td>
                <td>
                  <div className="select">
                    <select
                      className="select"
                      name="sleeve"
                      value={form[index].sleeve}
                      onChange={(event) => handleChange(event, index)}
                    >
                      <option value="SHORT">半袖</option>
                      <option value="LONG">長袖</option>
                    </select>
                  </div>
                </td>
                <td>
                  <div className="select">
                    <select
                      name="size"
                      className="select"
                      onChange={(event) => handleChange(event, index)}
                    >
                      {sizes.map((el) => {
                        return (
                          <option value={el} key={el}>
                            {el}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </td>
                <td>
                  <input
                    className="input"
                    type="text"
                    name="name"
                    value={form[index].name}
                    onChange={(event) => handleChange(event, index)}
                    maxLength={12}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
