import React from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';
import { colorCodes } from '../../settings';

interface DesignProps {
  isActive?: Boolean;
}
const ColorsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
  grid-gap: 10px;
  > * {
    width: 100%;
  }
`;
const Color = styled.a<DesignProps>`
  > * {
    width: 100%;
  }
  border: 1px solid ${(props) => (props.isActive ? 'blue' : '#cdcdcd')};
  background-color: ${(props) => props.color};
  height: 40px;
`;
const ColorInner = styled.img<DesignProps>`
  background-color: ${(props) => props.color};
  width: 100%;
  height: 100%;
`;

const Colors = ({ onClickColor, color }) => {
  const _onClickColor = useCallback(
    (el) => {
      onClickColor(el);
    },
    [onClickColor]
  );

  return (
    <ColorsGrid>
      {colorCodes.map((el) => {
        return (
          <Color
            href="#"
            isActive={color.name === el.name}
            onClick={() => _onClickColor(el)}
            key={el.name}
          >
            <ColorInner color={el.colorCode} />
          </Color>
        );
      })}
    </ColorsGrid>
  );
};

export default Colors;
