import React from 'react';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import {
  UNIT_PRICE,
  UNIT_PRICE_GROUP_DISCOUNT,
  GROUP_DISCOUNT,
  SHIPPING,
} from '../../settings';

const BodyTab = styled.li`
  height: 300px;
  flex: 1;
  margin: 10px;
`;

const TableContainer = styled.div`
  overflow-x: auto;
  border: 1px solid #dbdbdb;
`;
const TdData = styled.td`
  font-size: 0.9rem;
  white-space: nowrap;
  text-align: right !important;
`;
const TdHeading = styled.td`
  font-size: 0.9rem;
  white-space: nowrap;
`;

export default ({ style }) => {
  return (
    <BodyTab style={style}>
      <Calculator />
    </BodyTab>
  );
};

const Calculator = () => {
  const [quantity, setQuantity] = useState<string>('');

  const onChangeQuantity = useCallback((e: any) => {
    const quantity = e.currentTarget.value;
    setQuantity(quantity);
  }, []);

  const isDiscountOne = Number(quantity) >= 30;
  const unitPrice = isDiscountOne ? UNIT_PRICE_GROUP_DISCOUNT : UNIT_PRICE;
  const unitPriceAmount = unitPrice * Number(quantity);
  const campaign = isDiscountOne ? GROUP_DISCOUNT * Number(quantity) : 0;

  const totalPrice = isDiscountOne
    ? unitPrice * Number(quantity) - campaign
    : unitPrice * Number(quantity) + (0 < Number(quantity) ? SHIPPING : 0);

  return (
    <div>
      <div className="field">
        <label className="label">ご希望の枚数</label>
        <div className="control">
          <div className="select">
            <select name="" id="" onChange={onChangeQuantity}>
              <option value={0}>0</option>
              {[...new Array(50)].map((el, index) => {
                return (
                  <option value={index + 5} key={index}>
                    {index + 5}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>

      <h2>見積り金額(税込み)</h2>
      <TableContainer>
        <table className="table">
          <tbody>
            <tr>
              <TdHeading>シャツ</TdHeading>
              <TdData>{unitPrice.toLocaleString()}円</TdData>
              <TdData>{quantity || '0'}枚</TdData>
              <TdData>{unitPriceAmount.toLocaleString()}円</TdData>
            </tr>
            {0 < Number(quantity) && (
              <tr>
                <TdHeading>送料</TdHeading>
                <TdData>{campaign ? 0 : SHIPPING.toLocaleString()}円</TdData>
                <TdData>-</TdData>
                <TdData>{campaign ? 0 : SHIPPING.toLocaleString()}円</TdData>
              </tr>
            )}
            {!!campaign && (
              <tr>
                <TdHeading>
                  キャンペーン割引
                  <br />
                  一枚あたり
                </TdHeading>
                <TdData>-{GROUP_DISCOUNT}円</TdData>
                <TdData>{quantity}</TdData>
                <TdData>-{campaign.toLocaleString()}円</TdData>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <TdHeading>合計</TdHeading>
              <TdData></TdData>
              <TdData></TdData>
              <TdData>{totalPrice.toLocaleString()}円</TdData>
            </tr>
          </tfoot>
        </table>
      </TableContainer>

      <TableContainer style={{ marginTop: 10 }}>
        <table className="table">
          <tbody>
            <tr>
              <TdHeading>1枚あたり</TdHeading>
              <TdData>
                {(
                  Math.round(totalPrice / quantity) || unitPrice
                ).toLocaleString()}
                円
              </TdData>
            </tr>
          </tbody>
        </table>
      </TableContainer>
    </div>
  );
};
