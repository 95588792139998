import React, { useState, useEffect, useCallback } from 'react';
// import { useCallback, useState } from "react";
import $ from 'jquery';
import styled from 'styled-components';
import { useCustomize } from '../../modules/context.tsx';
import { saveDesign, getDesign, getOrder } from '../../repository';
import { SET_PARTS_LIST } from '../../modules/reducer';
import { updateCustomize } from '../../modules/reducer';
import { MarkingProps, PreviewProps } from '../../types';
const DESIGNS = process.env.PUBLIC_URL + '/assets/designs';
const SPONSORS = process.env.PUBLIC_URL + '/assets/sponsors';

function iosCopyToClipboard(el) {
  var oldContentEditable = el.contentEditable,
    oldReadOnly = el.readOnly,
    range = document.createRange();

  el.contentEditable = true;
  el.readOnly = false;
  range.selectNodeContents(el);

  var s = window.getSelection();
  if (s) {
    s.removeAllRanges();
    s.addRange(range);

    el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

    el.contentEditable = oldContentEditable;
    el.readOnly = oldReadOnly;

    document.execCommand('copy');
  }
}

const size = 350;
const previewSize = 350;

const Viewer = styled.div<PreviewProps>`
  position: relative;
  background-color: ${({ preview }) => (preview ? 'white' : '#fafafa')};
  flex: 1;
  max-height: 500px;
  left: ${({ preview }) => (preview ? '-30px' : '0')};
`;

const Markings = styled.div<PreviewProps>`
  margin: auto;
  left: 0;
  right: 0;
  // 一箇所だけで良い
  position: ${({ preview }) => (preview ? 'relative' : 'absolute')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  width: ${({ preview }) => (preview ? previewSize : size)}px;
  height: ${({ preview }) => (preview ? previewSize - 50 : size - 50)}px;
`;

const Design = styled.div<PreviewProps>`
  margin: auto;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ preview }) => (preview ? previewSize : size)}px;
  height: ${({ preview }) => (preview ? previewSize - 50 : size - 50)}px;
  > svg {
    margin: auto;
    width: 100%;
    height: 100%;
  }
`;

const Neck = styled.div<PreviewProps>`
  margin: auto;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ preview }) => (preview ? previewSize : size)}px;
  height: ${({ preview }) => (preview ? previewSize - 50 : size - 50)}px;
  > svg {
    margin: auto;
    width: 100%;
    height: 100%;
  }
`;

const MARKING_SIZE = {
  number: 30,
  emblem: 15,
  sponsor: 56,
};

const Sponsor = styled.div<MarkingProps>`
  position: absolute;
  width: ${MARKING_SIZE.sponsor}px;
  left: ${(props) => `${props.left}px`};
  top: ${(props) => `${props.top}px`};
  margin-left: -${MARKING_SIZE.sponsor / 2 - 5}px;
  z-index: 1;
`;

const TextMarking = styled.span<MarkingProps>`
  position: absolute;
  font-weight: bold;
  left: ${(props) => `${props.left}px`};
  top: ${(props) => `${props.top}px`};
  transform: ${(props) => `${props.transform || 'none'}`};
  color: ${(props) => `${props.colorCode || '#000'}`};
  font-family: ${(props) => `${props.font || ''}`};
  /* text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff; */
  z-index: 1000;

  font-size: ${(props) => (props.size ? props.size : 12)}px;

  width: 100px;
  height: 100px;
  margin-left: -40px;
  margin-top: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageMarking = styled.img<MarkingProps>`
  position: absolute;
  width: ${MARKING_SIZE.emblem}px;
  left: ${(props) => `${props.left}px`};
  top: ${(props) => `${props.top}px`};
  transform: ${(props) => `${props.transform || 'none'}`};
  margin-left: -${MARKING_SIZE.emblem / 2 - 5}px;
  z-index: 1000;
`;

const ActionsLeft = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
`;

const ActionsRight = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
`;

const SaveButton = styled.button``;
const ContactButton = styled.button`
  background-color: #ff8c00 !important;
`;

const SponsorComponent = ({ o }) => {
  const [sponsor, setSponsor] = useState<string | null>(null);
  // スポンサーを表示
  useEffect(() => {
    const designFilePath = `${SPONSORS}/${o.code}.svg`;
    fetch(designFilePath)
      .then((res) => res.text())
      .then(async (rawSvg) => {
        setSponsor(rawSvg);
      });
  }, [o.code, o.value]);

  // スポンサーの色反映
  useEffect(() => {
    const $svg = $(`#sponsorSvg-${o.code}`);
    const { colorCode } = o.color;
    if (!colorCode) {
      // 未設定の場合はsvg自体の初期の色設定を使うため、
      // 色付けを行わない
      return;
    }
    const $path = $svg.find('path, polygon, rect');
    $path.each((_, e) => {
      $(e).attr('style', `fill: ${colorCode}`);
    });
  }, [o.code, o.color]);

  return (
    <Sponsor
      id={`sponsorSvg-${o.code}`}
      left={o.position.left}
      top={o.position.top}
      size={o.position.size}
      colorCode={o.color.colorCode}
      dangerouslySetInnerHTML={{ __html: sponsor }}
    />
  );
};

export default ({ preview }) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [designUrl, setDesignUrl] = useState('');
  const [customize, dispatch] = useCustomize();
  const {
    shirt: {
      design: { id: designId, parts },
    },
    neck: { type: neckId, color },
  } = customize;

  const [design, setDesign] = useState<string | null>(null);
  const [neck, setNeck] = useState<string | null>(null);

  const saveDesignProcess = useCallback(async () => {
    const result = await saveDesign(customize);

    // 正常に保存できたので、codeを反映
    window.history.pushState(null, '', `?code=${result.id}`);

    window.LineIt.loadButton();
    setDesignUrl(window.location.href);
  }, [customize]);

  const onClickSaveDesign = useCallback(async () => {
    await saveDesignProcess();

    setIsModalShown(true);
  }, [saveDesignProcess]);

  const onClickContact = useCallback(async () => {
    await saveDesignProcess();

    setIsModalShown(true);
  }, [saveDesignProcess]);

  // デザインコードからデザインを反映
  useEffect(() => {
    async function fetchDesignData(code: string) {
      if (preview) {
        return;
      }
      try {
        const fetchedCustomize = await getDesign(code);
        if (!fetchedCustomize.data()) {
          const fetchedOrder = await getOrder(code);
          if (!fetchedOrder.data()) {
            alert('ご指定のデザインが見つかりません');
          }
          dispatch(updateCustomize(fetchedOrder.data()));
          return;
        }
        dispatch(updateCustomize(fetchedCustomize.data()));
        return;
      } catch (err) {
        // TODO: URLのcodeを削除;
        return;
      }
    }

    let urlParamStr = window.location.search;

    if (!urlParamStr) {
      return;
    }

    //?を除去
    urlParamStr = urlParamStr.substring(1);
    let params: any = {};
    //urlパラメータをオブジェクトにまとめる
    urlParamStr.split('&').forEach((param) => {
      const temp = param.split('=');
      //pramsオブジェクトにパラメータを追加
      params = {
        ...params,
        [temp[0]]: temp[1],
      };
    });

    if (params.code) {
      fetchDesignData(params.code);
    }
  }, [dispatch, preview]);

  // デザインデータの更新
  useEffect(() => {
    const designFilePath = `${DESIGNS}/design_${designId}.svg`;
    fetch(designFilePath)
      .then((res) => res.text())
      .then(async (rawSvg) => {
        // デザインデータを反映
        setDesign(rawSvg);

        if (customize.shirt.design.parts.length) {
          // すでにパーツは反映済みなので
          return;
        }
        // パーツ未反映なので、セットする
        const $svg = $('#svg');
        const $stripe = $svg.find('[id^="parts-"');

        const partsList: string[] = [];
        $.map($stripe, (el) => {
          const partsName = $(el).attr('id').split('-')[1];
          partsList.push(partsName);
        });
        dispatch({
          type: SET_PARTS_LIST,
          value: partsList,
        });
      });
  }, [customize.shirt.design.parts.length, designId, dispatch]);

  // パーツの色反映
  useEffect(() => {
    const $svg = $('#svg');
    // console.log($svg.find('[id]'))
    // console.log($svg.find('#stripe1'))
    parts.map((el: any) => {
      const { name, colorCode } = el;
      if (!colorCode) {
        // 未設定の場合はsvg自体の初期の色設定を使うため、
        // 色付けを行わない
        return false;
      }
      const $stripe = $svg.find(`#parts-${name}`);
      const $path = $stripe.find('path, polygon, rect');
      $path.each((index, e) => {
        // let existingStyle = $(e).attr("style");
        $(e).attr('style', `fill: ${colorCode}`);
      });
      return false;
    });
  }, [design, parts]);

  // 襟デザインデータの更新
  useEffect(() => {
    const designFilePath = `${DESIGNS}/neck_${neckId}.svg`;
    fetch(designFilePath)
      .then((res) => res.text())
      .then(async (rawSvg) => {
        setNeck(rawSvg);
      });
  }, [neckId]);

  // 襟の色反映
  useEffect(() => {
    const { colorCode } = color;
    if (!colorCode) {
      // 未設定の場合はsvg自体の初期の色設定を使うため、
      // 色付けを行わない
      return;
    }
    const $stripe = $(`#neckSvg [id^=parts]`);
    const $path = $stripe.find('path, polygon, rect');
    $path.each((_, e) => {
      $(e).attr('style', `fill: ${colorCode}`);
    });
  }, [color, design, parts]);

  // デザインデータに基づいたパーツの設定
  const markings = customize.shirt.marking;
  return (
    <Viewer preview={preview}>
      {!preview && (
        <ActionsLeft>
          <ContactButton
            className="button is-primary is-small"
            onClick={() => onClickContact()}
          >
            お問い合わせ
          </ContactButton>
        </ActionsLeft>
      )}
      {!preview && (
        <ActionsRight>
          <SaveButton
            className="button is-primary is-small"
            onClick={() => onClickSaveDesign()}
          >
            デザイン保存
          </SaveButton>
        </ActionsRight>
      )}
      {/* <img src={`${DESIGNS}/design_2.svg`} alt=""/> */}
      <Design
        dangerouslySetInnerHTML={{ __html: design }}
        id="svg"
        preview={preview}
      />
      <Neck
        dangerouslySetInnerHTML={{ __html: neck }}
        id="neckSvg"
        preview={preview}
      />
      <Markings preview={preview}>
        {markings.map((el, index) => {
          switch (el.id) {
            case 'number':
            case 'team':
            case 'name':
              return (
                <TextMarking
                  key={index}
                  left={el.position.left}
                  top={el.position.top}
                  size={el.position.size}
                  colorCode={el.color.colorCode}
                  transform={el.position.transform}
                  font={el.font.id}
                >
                  {el.value}
                </TextMarking>
              );
            case 'emblem':
            case 'originalLogo':
              return (
                <ImageMarking
                  key={index}
                  src={el.value}
                  left={el.position.left}
                  top={el.position.top}
                  transform={el.position.transform}
                  size={el.position.size}
                />
              );
            case 'sponsor':
              return <SponsorComponent key={index} o={el} />;
          }
          return null;
        })}
        {/* {sponsors.map((o, index) => {
            return (
              <Sponsor
                key={index}
                left={el.position.left}
                top={el.position.top}
                size={el.position.size}
                colorCode={el.color.colorCode}
                dangerouslySetInnerHTML={{ __html: o }} id="sponsorSvg"
              />
            );
        })} */}
      </Markings>

      <div className={`modal ${isModalShown ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">デザインが保存されました</p>
            </header>

            <section className="modal-card-body">
              <p>デザインURL:</p>
              <input
                className="input"
                type="text"
                defaultValue={designUrl}
                readOnly
                onClick={(ev) => {
                  console.log(ev);
                  iosCopyToClipboard(ev.currentTarget);
                  alert('デザインURLをコピーしました');
                }}
              />
            </section>
            <footer className="modal-card-foot">
              <div
                className="line-it-button"
                data-lang="ja"
                data-type="share-a"
                data-ver="3"
                data-url={designUrl}
                data-color="default"
                data-size="large"
                data-count="false"
              ></div>

              <button
                style={{ fontWeight: 'bold' }}
                className="button is-success is-small"
                onClick={() => {
                  window.location.href = 'line://ti/p/@ufi4374z';
                }}
              >
                Lineで問い合わせる
              </button>
            </footer>
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => setIsModalShown(false)}
        ></button>
      </div>
    </Viewer>
  );
};
