const DESIGN_ICONS = process.env.PUBLIC_URL + '/assets/icons/designs';
const EMBLEM_ICONS_PATH = process.env.PUBLIC_URL + '/assets/icons/emblems';
const FONT_ICONS_PATH = process.env.PUBLIC_URL + '/assets/icons/names';
const NUMBER_ICONS_PATH = process.env.PUBLIC_URL + '/assets/icons/numbers';
const SPONSOR_ICONS_PATH = process.env.PUBLIC_URL + '/assets/icons/sponsors';
const POSITION_ICON_PATH = process.env.PUBLIC_URL + '/assets/icons/positions';

export const colorCodes = [
  { name: 'ブラック', colorCode: '#000000' },
  { name: 'ホワイト', colorCode: '#ffffff' },
  { name: 'イエロー', colorCode: '#FFD700' },
  { name: 'エンジ', colorCode: '#8b0000' },
  { name: 'サックス', colorCode: '#87ceeb' },
  { name: 'シルバー', colorCode: '#a9a9a9' },
  { name: 'ライム', colorCode: '#adff2f' },
  { name: 'グリーン', colorCode: '#006400' },
  { name: 'パープル', colorCode: '#8a2be2' },
  { name: 'オレンジ', colorCode: '#ff8c00' },
  { name: 'ブルー', colorCode: '#003F8E' },
  { name: 'ライトグリーン', colorCode: '#008000' },
  { name: 'ピンク', colorCode: '#FF3399' },
  { name: 'ラベンダー', colorCode: '#CC99FF' },
  { name: 'ゴールド', colorCode: '#daa520' },
  { name: 'ネイビー', colorCode: '#333366' },
  { name: 'サクラ', colorCode: '#FF99CC' },
  { name: 'アクア', colorCode: '#66CCFF' },
  { name: 'カーキ', colorCode: '#808000' },
  { name: 'ターコイズ', colorCode: '#40e0d0' },
  { name: 'レッド', colorCode: '#CC0000' },
  { name: 'ヘビーグレー', colorCode: '#333333' },
  { name: '蛍光イエロー', colorCode: '#ffff00' },
  { name: '蛍光ピンク', colorCode: '#FF3399' },
  { name: '蛍光グリーン', colorCode: '#00ff00' },
];

export const designs = [
  `V005`,
  `V007`,
  `V010`,
  `V015`,
  `5`,
  `6`,
  `8`,
  `9`,
  `10`,
  `11`,
  `12`,
  `13`,
].map((key) => ({
  id: key,
  imageUrl: `${DESIGN_ICONS}/${key}.png`,
}));

export const EMBLEM_ICONS = [
  `${EMBLEM_ICONS_PATH}/acm.png`,
  `${EMBLEM_ICONS_PATH}/ars.png`,
  `${EMBLEM_ICONS_PATH}/atl.png`,
  `${EMBLEM_ICONS_PATH}/bcn.png`,
  `${EMBLEM_ICONS_PATH}/bvb.png`,
  `${EMBLEM_ICONS_PATH}/che.png`,
  `${EMBLEM_ICONS_PATH}/fcb.png`,
  `${EMBLEM_ICONS_PATH}/int.png`,
  `${EMBLEM_ICONS_PATH}/juv.png`,
  `${EMBLEM_ICONS_PATH}/juve.png`,
  `${EMBLEM_ICONS_PATH}/lag.png`,
  `${EMBLEM_ICONS_PATH}/liv.png`,
  `${EMBLEM_ICONS_PATH}/live.png`,
  `${EMBLEM_ICONS_PATH}/mci.png`,
  `${EMBLEM_ICONS_PATH}/psg.png`,
  `${EMBLEM_ICONS_PATH}/rbb.png`,
  `${EMBLEM_ICONS_PATH}/rma.png`,
  `${EMBLEM_ICONS_PATH}/original.png`,
];

export const FONT_ICONS = [
  'VS03',
  'VS04',
  'VS05',
  'VS07',
  'VS08',
  'VS09',
  'VS12',
  'VS13',
].map((key) => ({
  id: key,
  imageUrl: `${FONT_ICONS_PATH}/${key}.png`,
}));

export const NUMBER_FONT = ['VB02', 'VB05', 'VB06', 'VB08'].map((key) => ({
  id: key,
  imageUrl: `${NUMBER_ICONS_PATH}/${key}.png`,
}));

export const SPONSOR_ICONS = [
  `1`,
  `10`,
  `11`,
  `12`,
  `13`,
  `14`,
  `2`,
  `3`,
  `4`,
  `5`,
  `6`,
  `7`,
  `8`,
  `9`,
  `original`,
].map((key) => ({
  id: key,
  imageUrl: `${SPONSOR_ICONS_PATH}/${key}.png`,
}));

// 横方向
const RIGHT = 74;
const LEFT = 110;
const SLEEVE_DIFF = 39;
const RIGHT_SLEEVE_HORIZONTAL = RIGHT - SLEEVE_DIFF - 6;
const LEFT_SLEEVE_HORIZONTAL = LEFT + SLEEVE_DIFF;

const RIGHT_SLEEVE_IMAGE_HORIZONTAL = RIGHT - SLEEVE_DIFF - 1;
const LEFT_SLEEVE_IMAGE_HORIZONTAL = LEFT + SLEEVE_DIFF + 3;

const CENTER_HORIZOINTAL = RIGHT + (LEFT - RIGHT) / 2;
const BACK_CENTER_HORIZOINTAL = RIGHT + (LEFT - RIGHT) / 2 + 153;

// 縦方向
const CHEST = 115;
const CENTER_VERTICAL = CHEST + 20 - 5;
const BACK_UPPER_VERTICAL = CHEST - 13;
const SLEEVE = BACK_UPPER_VERTICAL - 5;
const BACK_BOTTOM_VERTICAL = CENTER_VERTICAL + 30;

// サイズ
const LARGE = 50;

const SLEEVE_MARKING_SIZE = {
  size: '9px',
};

const ROTATE_FOR_RIGHT_SLEEVE = {
  transform: 'rotate(80deg)',
};

const ROTATE_FOR_LEFT_SLEEVE = {
  transform: 'rotate(-80deg)',
};

const RIGHT_CHEST = {
  top: CHEST,
  left: RIGHT,
};

const RIGHT_CHEST_TEXT = {
  top: CHEST - 2,
  left: RIGHT - 3,
};

const LEFT_CHEST = {
  top: CHEST,
  left: LEFT,
};

const LEFT_CHEST_TEXT = {
  top: CHEST - 2,
  left: LEFT - 3,
};

const CENTER_CHEST = {
  top: CENTER_VERTICAL,
  left: CENTER_HORIZOINTAL,
};

const CENTER_CHEST_TEXT = {
  top: CENTER_VERTICAL + 5,
  left: CENTER_HORIZOINTAL - 3,
};

const CENTER_BACK = {
  top: CENTER_VERTICAL,
  left: BACK_CENTER_HORIZOINTAL,
  size: LARGE,
};

const UPPER_BACK = {
  top: BACK_UPPER_VERTICAL,
  left: BACK_CENTER_HORIZOINTAL,
};

const BOTTOM_BACK = {
  top: BACK_BOTTOM_VERTICAL,
  left: BACK_CENTER_HORIZOINTAL,
};

const RIGHT_SLEEVE = {
  top: SLEEVE,
  left: RIGHT_SLEEVE_HORIZONTAL,
};

const LEFT_SLEEVE = {
  top: SLEEVE,
  left: LEFT_SLEEVE_HORIZONTAL,
};

const RIGHT_SLEEVE_IMAGE = {
  top: SLEEVE,
  left: RIGHT_SLEEVE_IMAGE_HORIZONTAL,
};

const LEFT_SLEEVE_IMAGE = {
  top: SLEEVE,
  left: LEFT_SLEEVE_IMAGE_HORIZONTAL,
};

const NONE = {
  top: -9999,
  left: -9999,
};

export const POSITIONS = {
  number: [
    {
      id: 'NONE',
      name: '無し',
      img: `${POSITION_ICON_PATH}/無し.png`,
      ...NONE,
    },
    {
      id: 'RIGHT_CHEST',
      name: '右胸',
      img: `${POSITION_ICON_PATH}/number/右胸.png`,
      size: '15',
      ...RIGHT_CHEST_TEXT,
    },
    {
      id: 'LEFT_CHEST',
      name: '左胸',
      size: '15',
      img: `${POSITION_ICON_PATH}/number/左胸.png`,
      ...LEFT_CHEST_TEXT,
    },
    {
      id: 'CHEST_CENTER',
      name: '胸中央',
      size: '15',
      img: `${POSITION_ICON_PATH}/number/胸中央.png`,
      ...CENTER_CHEST_TEXT,
    },
    {
      id: 'BACK_CENTER',
      name: '背中中央',
      img: `${POSITION_ICON_PATH}/number/背中.png`,
      ...CENTER_BACK,
    },
  ],
  team: [
    {
      id: 'NONE',
      name: '無し',
      img: `${POSITION_ICON_PATH}/無し.png`,
      ...NONE,
    },
    {
      id: 'RIGHT_CHEST',
      name: '右胸',
      img: `${POSITION_ICON_PATH}/team/右胸.png`,
      size: '7',
      ...RIGHT_CHEST_TEXT,
    },
    {
      id: 'LEFT_CHEST',
      name: '左胸',
      size: '7',
      img: `${POSITION_ICON_PATH}/team/左胸.png`,
      ...LEFT_CHEST_TEXT,
    },
    {
      id: 'CHEST_CENTER',
      name: '胸中央',
      img: `${POSITION_ICON_PATH}/team/胸中央.png`,
      size: '14',
      ...CENTER_CHEST_TEXT,
    },
    {
      id: 'BACK_UPPER',
      name: '背中上部',
      img: `${POSITION_ICON_PATH}/team/背中上.png`,
      ...UPPER_BACK,
    },
    {
      id: 'BACK_LOWER',
      name: '背中下部',
      img: `${POSITION_ICON_PATH}/team/背中下.png`,
      ...BOTTOM_BACK,
    },
    {
      id: 'RIGHT_SIDE',
      name: '右袖',
      img: `${POSITION_ICON_PATH}/team/右袖.png`,
      ...RIGHT_SLEEVE,
      ...ROTATE_FOR_RIGHT_SLEEVE,
      ...SLEEVE_MARKING_SIZE,
    },
    {
      id: 'LEFT_SIDE',
      name: '左袖',
      img: `${POSITION_ICON_PATH}/team/左袖.png`,
      ...LEFT_SLEEVE,
      ...ROTATE_FOR_LEFT_SLEEVE,
      ...SLEEVE_MARKING_SIZE,
    },
  ],
  name: [
    {
      id: 'NONE',
      name: '無し',
      img: `${POSITION_ICON_PATH}/無し.png`,
      ...NONE,
    },
    {
      id: 'BACK_UPPER',
      name: '背中上部',
      img: `${POSITION_ICON_PATH}/name/背中上.png`,
      ...UPPER_BACK,
    },
    {
      id: 'BACK_LOWER',
      name: '背中下部',
      img: `${POSITION_ICON_PATH}/name/背中下.png`,
      ...BOTTOM_BACK,
    },
    {
      id: 'RIGHT_SIDE',
      name: '右袖',
      img: `${POSITION_ICON_PATH}/name/右袖.png`,
      ...RIGHT_SLEEVE,
      ...ROTATE_FOR_RIGHT_SLEEVE,
      ...SLEEVE_MARKING_SIZE,
    },
    {
      id: 'LEFT_SIDE',
      name: '左袖',
      img: `${POSITION_ICON_PATH}/name/左袖.png`,
      ...LEFT_SLEEVE,
      ...ROTATE_FOR_LEFT_SLEEVE,
      ...SLEEVE_MARKING_SIZE,
    },
  ],
  emblem: [
    {
      id: 'NONE',
      name: '無し',
      img: `${POSITION_ICON_PATH}/無し.png`,
      ...NONE,
    },
    {
      id: 'RIGHT_CHEST',
      name: '右胸',
      img: `${POSITION_ICON_PATH}/logo/右胸.png`,
      ...RIGHT_CHEST,
    },
    {
      id: 'LEFT_CHEST',
      name: '左胸',
      img: `${POSITION_ICON_PATH}/logo/左胸.png`,
      ...LEFT_CHEST,
    },

    // https://trello.com/c/yyUBnBji
    // {
    //   id: "BACK",
    //   name: "背中",
    //   img: `${POSITION_ICON_PATH}/logo/背中.png`,
    //   ...CENTER_BACK
    // },
    // {
    //   id: "CHEST_CENTER",
    //   name: "胸中央",
    //   img: `${POSITION_ICON_PATH}/logo/胸.png`,
    //   ...CENTER_CHEST
    // },
    {
      id: 'RIGHT_SIDE',
      name: '右袖',
      img: `${POSITION_ICON_PATH}/logo/右袖.png`,
      ...RIGHT_SLEEVE_IMAGE,
      ...ROTATE_FOR_RIGHT_SLEEVE,
    },
    {
      id: 'LEFT_SIDE',
      name: '左袖',
      img: `${POSITION_ICON_PATH}/logo/左袖.png`,
      ...LEFT_SLEEVE_IMAGE,
      ...ROTATE_FOR_LEFT_SLEEVE,
    },
  ],
  sponsor: [
    {
      id: 'NONE',
      name: '無し',
      img: `${POSITION_ICON_PATH}/無し.png`,
      ...NONE,
    },
    {
      id: 'CHEST_CENTER',
      name: '胸中央',
      img: `${POSITION_ICON_PATH}/sponsor/胸中央.png`,
      ...CENTER_CHEST,
    },
  ],
  originalLogo: [
    {
      id: 'NONE',
      name: '無し',
      img: `${POSITION_ICON_PATH}/無し.png`,
      ...NONE,
    },
    {
      id: 'RIGHT_CHEST',
      name: '右胸',
    },
    {
      id: 'LEFT_CHEST',
      name: '左胸',
    },
    {
      id: 'CHEST_CENTER',
      name: '胸中央',
    },
    {
      id: 'BACK_CENTER',
      name: '背中中央',
    },
    {
      id: 'SIDE',
      name: '袖',
    },
  ],
};

export const necks = [
  {
    id: 1,
    name: '丸襟',
  },
  {
    id: 2,
    name: 'Vネック',
  },
];

export const UNIT_PRICE = 2900;
export const UNIT_PRICE_GROUP_DISCOUNT = 2600;
export const SHIPPING = 1100;
export const GROUP_DISCOUNT = 200;

export const sizes = ['150', '160', 'S', 'M', 'L', 'O', 'XO', '2XO', '3XO'];
