import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import './App.css';
import Controller from './components/organisms/Controller.tsx';
import Viewer from './components/organisms/Viewer.tsx';
import 'bulma/css/bulma.css';
import { CustomizeProvider } from './modules/context';
import { signInAnonymously } from './auth';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  max-width: 800px;
  margin: auto;
  border-left: 1px solid #fafafa;
  border-right: 1px solid #fafafa;
`;

function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  useEffect(() => {
    async function signIn() {
      await signInAnonymously();
      setIsSignedIn(true);
    }
    signIn();
  }, []);

  return (
    <Container>
      {isSignedIn && (
        <CustomizeProvider>
          <Controller />
          <Viewer />
        </CustomizeProvider>
      )}
    </Container>
  );
}
export default App;
